import { ref } from 'vue'
import type { FormStateType } from '../types/form'

export default function useNewsletter() {
  const state = ref<FormStateType>('idle')

  async function subscribe(email: string, sendinblueListId: number, location?: string, area?: string) {
    state.value = 'loading'

    const country = await fetch('https://apps.outfit7.com/rest/geoip/v1/countries/jsonp')
      .then(async response => response.text())
      .then(data => {
        const match = data.match(/geoip\(\{"country":"([A-Z]{2})"\}\);/)
        return match && match[1]
      })

    return new Promise<void>((resolve, reject) => {
      fetch('https://outfit7.com/subscribe', {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          mailList: sendinblueListId,
          countryCode: country,
          location,
          area
        })
      })
        .then(async response => {
          if (response.ok) {
            state.value = 'success'
            resolve()
          }
          else {
            throw new Error(await response.json())
          }
        })
        .catch(() => {
          state.value = 'error'
          reject()
        })
        .finally(() => {
          setTimeout(() => {
            state.value = 'idle'
          }, 3000)
        })
    })
  }

  return { subscribe, state }
}
